<template>
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
          <el-form-item label="">
            <el-select v-model="filters.areaStr" placeholder="请选择省份" filterable clearable>
              <el-option
                  v-for="item in areaArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <!--    <el-table-->
    <!--        :data="tableData"-->
    <!--        border-->
    <!--        style="width: 100%"-->
    <!--        v-loading="listLoading"-->
    <!--        ref="multipleTable"-->
    <!--        @current-change='currentChange'-->
    <!--        :highlight-current-row='true'-->
    <!--    >-->
    <!--      <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>-->
    <!--    </el-table>-->
    <!--底部工具条-->
    <el-col :span="24" class="pageBar">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pages.pageIndex"
          :page-sizes="pages.pageArr"
          :page-size="pages.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pages.dataCount">
      </el-pagination>
    </el-col>
  </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
  getAreaDictionaryList,
} from '../../api/api'

export default {
  components:{Toolbar},
  data() {
    return {
      buttonList:[],
      tableData: [],
      areaArr: [],
      listLoading: false,
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      filters: {
        keyWord: '',
        areaStr: '',
        entyType: '',
      },



    }
  },
  methods: {
    rowClass () {
      return 'padding:8px!important;'
    },
    callFunction(item) {
      this[item.func].apply(this, item);
    },
    activeChange(val) {
      if(val == '2') {
        this.viewsApplicationWidth = "1200px"
      } else {
        this.viewsApplicationWidth = "60%"
      }
    },
    // 列表查询接口
    getListData(){
      var params = {
        pageIndex: this.pages.pageIndex,
        pageSize: this.pages.pageSize,
        searchKey: this.filters.keyWord,
        provinceCode: this.filters.areaStr,
        projectStatus:55,
      }
      // if(this.filters.entyType){
      //   params.conclusionStatus = this.filters.entyType;
      // }
      // this.listLoading = true
      // getDrProjectFiledtList(params).then(res => {
      //   var result = res.data
      //   if(result.success) {
      //     this.tableData = result.response.data
      //     this.pages.dataCount = result.response.dataCount
      //   }
      //   this.listLoading = false
      // })
    },
    // 获取省
    getAreaList() {
      var params = {
        parentId: 1,
      }
      getAreaDictionaryList(params).then(res => {
        if (res.data.success) {
          this.areaArr = res.data.response.reverse();
        }
      })
    },
    // 查询
    getProjectList() {
      this.pages.pageIndex = 1
      this.getListData();
    },


    // 点击table某一行
    currentChange(val) {
      this.needData = val
      // console.log(val,'val')
      const myArray = [];

// 使用push方法将整个对象添加到数组中
      myArray.push(val);
      this.needDataContact = myArray
    },
    // 分页
    handleCurrentChange(val) {
      this.pages.pageIndex = val;
      this.getListData();
    },
    handleSizeChange(val){
      this.pages.pageSize = val;
      this.getListData();
    },
    // 时间格式化
    formatCreateTime: function(row, column) {
      return !row || row == ""
          ? ""
          : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
    },
    // 表格数据分页条数累计
    indexMethod(index) {
      var currentPage = this.pages.pageIndex
      var pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
  },
  watch: {

  },
  created(){
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
    this.buttonList = getButtonList(this.$route.path, routers);
    this.getListData();
    this.getAreaList()
  }
}
</script>

<style lang="stylus" scoped>
.projectName {
  text-align: center;
}
.projectSelect {
  margin-bottom: 15px;
}
.projectTitle {
  margin-bottom: 15px
}
.replyBtn {
  margin: 10px 0;
  color: #409eff;
  cursor: pointer;
  width: 30px;
}
.replyMessage {
  background: #eee;
  line-height: 30px;
  padding: 5px;
  border-radius: 2px;
  margin: 5px 15px;
}
.titleTimer {
  margin-left: 10px;
}
.green_t {
  color: green;
}
.red_t {
  color: red;
}
</style>
