import { render, staticRenderFns } from "./DeviceMonitoring.vue?vue&type=template&id=631c31d0&scoped=true"
import script from "./DeviceMonitoring.vue?vue&type=script&lang=js"
export * from "./DeviceMonitoring.vue?vue&type=script&lang=js"
import style0 from "./DeviceMonitoring.vue?vue&type=style&index=0&id=631c31d0&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "631c31d0",
  null
  
)

export default component.exports